<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article class="pt-4">
      <asom-card title="Current Order">
        <div class="w-72 pb-8">
          <asom-form-field
            label="Expected Delivery Date"
            required
            :state="inputStates('expectedDeliveryDate')"
            error="Expected Delivery Date is required"
          >
            <asom-input-date
              :min-date="new Date()"
              v-model="expectedDeliveryDate"
              :state="inputStates('expectedDeliveryDate')"
            />
          </asom-form-field>
        </div>
        <article
          v-if="itemsAvailable"
          class="bg-gray-100 rounded-md pl-4 pr-4 pb-4"
        >
          <div
            v-for="(item, index) in orderItems"
            :key="item.inventoryTypeId"
            class="pt-4"
          >
            <asom-card :title="item.inventoryTypeName">
              <template v-slot:header>
                <div class="flex justify-between">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{ item.inventoryTypeName }}
                  </h3>
                  <div>
                    <button
                      type="button"
                      @click="editItem(item, index)"
                      class="pr-4"
                    >
                      <asom-icon icon="pen"></asom-icon>
                    </button>
                    <button
                      type="button"
                      @click="removeItem(item.inventoryTypeId)"
                    >
                      <asom-icon icon="close"></asom-icon>
                    </button>
                  </div>
                </div>
              </template>
              <div class="space-y-4">
                <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                  <asom-form-field label="Quantity">
                    <span class="mt-3">{{ item.quantity }}</span>
                  </asom-form-field>
                  <asom-form-field label="Remarks">
                    <span class="mt-3">{{ item.remarks }}</span>
                  </asom-form-field>
                </div>
              </div>
            </asom-card>
          </div>
        </article>
        <article v-else>
          <asom-alert variant="info" title="Please Add Items to the Order" />
        </article>
        <asom-form-field
          label="Remarks"
          :state="inputStates('orderRemarks')"
          error="Remarks cannot be longer than 1000 characters"
        >
          <asom-input-textarea
            v-model="orderRemarks"
            :state="inputStates('orderRemarks')"
          />
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4 pt-8">
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.go(-1)"
          />
          <asom-button
            text="Add Item"
            @click="addItemClicked"
            :loading="isSubmitting"
          />
          <asom-button
            text="Confirm Order"
            @click="confirmOrderClicked"
            :loading="isSubmitting"
            :disabled="!itemsAvailable"
          />
        </div>
      </asom-card>
    </article>
    <asom-modal
      v-model="showAddItemModal"
      title="Select Item"
      :dismissible="false"
      size="md"
    >
      <div>
        <asom-alert
          variant="error"
          v-if="modalError"
          :error-message="modalError"
        />
        <article>
          <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            <asom-form-field
              :label="'Inventory Group'"
              :state="inputStates('formData.inventoryGroup')"
              error="Inventory Group is required"
            >
              <asom-input-select
                :state="inputStates('formData.inventoryGroup')"
                v-model="formData.inventoryGroup"
                :options="inventoryGroups"
              />
            </asom-form-field>
            <asom-form-field
              :label="'Item Type'"
              required
              :state="inputStates('formData.inventoryType')"
              error="Item Type is required"
            >
              <asom-input-select
                :state="inputStates('formData.inventoryType')"
                v-model="formData.inventoryType"
                :loading="isLoadingInventoryTypes"
                :options="inventoryTypeOptions"
                @search-change="queryInventoryTypes"
                ref="itemTypeSelection"
                placeholder="Type to search for Item Type"
              />
            </asom-form-field>
            <asom-form-field
              label="Quantity"
              required
              :state="inputStates('formData.quantity')"
              error="Quantity should be a valid number"
            >
              <asom-input-text
                type="number"
                v-model="formData.quantity"
                :state="inputStates('formData.quantity')"
              />
            </asom-form-field>
            <asom-form-field
              class="col-span-2"
              required
              label="Remarks"
              :state="inputStates('formData.remarks')"
              error="Remarks need more than 10 characters"
            >
              <asom-input-textarea
                type="text"
                v-model="formData.remarks"
                :state="inputStates('formData.remarks')"
                :maxlength="1000"
              />
            </asom-form-field>
            <div class="col-span-2 flex items-end justify-end space-x-2">
              <asom-q-r-code-scanner @detect="qrCodeResult">
              </asom-q-r-code-scanner>
              <asom-button
                text="Cancel"
                variant="secondary"
                @click="toggleAddItemModal(false)"
              />
              <asom-button
                text="Add to Order"
                @click="addToOrderClicked"
                :loading="isSubmitting"
              />
            </div>
          </form>
        </article>
      </div>
    </asom-modal>
    <asom-modal
      v-model="showEditItemModal"
      title="Update Item"
      :dismissible="false"
      size="md"
    >
      <div>
        <asom-alert
          variant="error"
          v-if="modalError"
          :error-message="modalError"
        />
        <article>
          <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            <asom-form-field
              :label="'Item Type'"
              required
              :state="inputStates('formData.inventoryType')"
              error="Item Type is required"
            >
              <asom-input-select
                disabled
                :state="inputStates('formData.inventoryType')"
                v-model="formData.inventoryType"
                :loading="isLoadingInventoryTypes"
                :options="inventoryTypeOptions"
                @search-change="queryInventoryTypes"
                placeholder="Type to search for Item Type"
              />
            </asom-form-field>
            <asom-form-field
              label="Quantity"
              required
              :state="inputStates('formData.quantity')"
              error="Quantity should be within the threshold limit"
            >
              <asom-input-text
                type="number"
                v-model="formData.quantity"
                :state="inputStates('formData.quantity')"
              />
            </asom-form-field>
            <asom-form-field
              required
              label="Remarks"
              :state="inputStates('formData.remarks')"
              error="Remarks need more than 10 characters"
            >
              <asom-input-textarea
                type="text"
                v-model="formData.remarks"
                :state="inputStates('formData.remarks')"
                :maxlength="1000"
              />
            </asom-form-field>
            <div class="col-span-2 flex justify-end space-x-4 pt-8">
              <asom-button
                text="Cancel"
                variant="secondary"
                @click="closeEditItemModal()"
              />
              <asom-button
                text="Submit"
                @click="editItemClicked"
                :loading="isSubmitting"
              />
            </div>
          </form>
        </article>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import remove from "lodash.remove";
import { useVuelidate } from "@vuelidate/core";
import inputStates from "@/mixins/inputStates";
import { required, minValue, maxValue, maxLength } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import {
  getListOfInventoryTypes,
  createInventoryOrder,
  getInventoryTypeDetails,
  getInventoryLastOrderAmount,
} from "../../../services/inventory.service";
import { InventoryGroups } from "../../../constants/APIEnums/inventoryEnums";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "CreateOrder",
  mixins: [inputStates],
  data() {
    return {
      error: null,
      modalError: null,
      isSubmitting: false,
      showAddItemModal: false,
      formData: {
        inventoryGroup: null,
        inventoryType: null,
        quantity: 0,
        remarks: null,
      },
      inventoryTypes: [],
      orderItems: [],
      expectedDeliveryDate: null,
      orderRemarks: null,
      inventoryTypeOptions: [],
      isLoadingInventoryTypes: false,
      showEditItemModal: false,
      editItemIndex: null,
      isLoadingTypeDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
    }),
    itemsAvailable() {
      if (this.orderItems.length > 0) return true;
      else return false;
    },
    inventoryGroups() {
      return [
        {
          label: InventoryGroups.GENERAL.NAME,
          value: InventoryGroups.GENERAL.VALUE,
        },
        {
          label: InventoryGroups.SMART_CARD.NAME,
          value: InventoryGroups.SMART_CARD.VALUE,
        },
        {
          label: InventoryGroups.SERIALISED_TAG.NAME,
          value: InventoryGroups.SERIALISED_TAG.VALUE,
        },
        {
          label: InventoryGroups.FIRST_AID.NAME,
          value: InventoryGroups.FIRST_AID.VALUE,
        },
      ];
    },
  },
  validations() {
    return {
      formData: {
        inventoryType: { required },
        quantity: {
          required,
          minValue: minValue(1),
          maxLength: maxValue(100000),
        },
        remarks: {
          required,
          maxLength: maxLength(1000),
        },
      },
      expectedDeliveryDate: { required },
      orderRemarks: {
        maxLength: maxLength(1000),
      },
    };
  },
  watch: {
    "formData.inventoryType": function(newValue, prevValue) {
      if (
        get(prevValue, "value") !== get(newValue, "value") &&
        newValue !== null
      ) {
        this.getTypeDetails(get(newValue, "value"));
        this.getLastOrderAmount(get(newValue, "value"));
      }
    },
    "formData.inventoryGroup": function(newValue, prevValue) {
      if (
        get(prevValue, "value") !== get(newValue, "value") &&
        !this.isLoadingTypeDetails
      ) {
        this.queryInventoryTypes("", true);
        this.formData.inventoryType = null;
        this.isLoadingTypeDetails = false;
        // this.$refs.itemTypeSelection.clearDropdownOptions();
      } else this.isLoadingTypeDetails = false;
    },
  },
  methods: {
    qrCodeResult(data) {
      let itemId = data;
      this.getTypeDetails(itemId);
      this.getLastOrderAmount(itemId);
    },
    async getTypeDetails(itemId) {
      this.error = null;
      this.isLoadingTypeDetails = true;
      const result = await getInventoryTypeDetails({
        inventoryTypeId: itemId,
        stationId: this.stationId,
      });
      if (result.success) {
        let itemDetails = get(result.payload, "inventoryTypeDetailsViewModel");
        this.formData.inventoryType = {
          label: get(itemDetails, "inventoryTypeName"),
          value: get(itemDetails, "inventoryTypeId"),
        };
        this.formData.inventoryGroup = {
          label: get(itemDetails, "inventoryGroup"),
          value: get(itemDetails, "inventoryGroupEnum"),
        };
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    async getLastOrderAmount(itemId) {
      const result = await getInventoryLastOrderAmount({
        inventoryTypeId: itemId,
        stationId: this.stationId,
        lineId: this.lineId,
      });
      if (result.success) {
        this.formData.quantity = get(result.payload, "orderAmount", 0);
      }
    },
    async queryInventoryTypes(query, allowEmptyQuery = false) {
      this.modalError = null;
      this.isLoadingInventoryTypes = true;
      if (!query || !allowEmptyQuery) {
        this.isLoadingInventoryTypes = false;
        this.inventoryTypeOptions = [];
      }
      const result = await getListOfInventoryTypes({
        stationId: this.stationId,
        inventoryGroup: get(this.formData.inventoryGroup, "value", null),
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.inventoryTypes = get(result, "payload.list", []);
        this.isLoadingInventoryTypes = false;
        this.modalError = null;
        this.inventoryTypeOptions = get(result, "payload.list").map(
          ({ inventoryTypeId, inventoryTypeName }) => ({
            label: inventoryTypeName,
            value: inventoryTypeId,
          })
        );
      } else {
        this.isLoadingInventoryTypes = false;
        this.modalError = result.payload;
        this.inventoryTypeOptions = [];
      }
    },
    addItemClicked() {
      this.toggleAddItemModal(true);
    },
    toggleAddItemModal(value) {
      this.formData.inventoryGroup = null;
      this.formData.inventoryType = null;
      this.formData.quantity = 0;
      this.formData.remarks = null;
      this.v$.formData.$reset();
      this.modalError = "";
      this.showAddItemModal = value;
      this.queryInventoryTypes("", true);
    },
    addToOrderClicked() {
      if (this.isSubmitting) return;
      this.modalError = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (!this.v$.formData.$invalid) {
        this.onAddToOrder();
      } else {
        this.modalError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    onAddToOrder() {
      let item = {};
      let isAlreadyAdded = false;
      item.inventoryTypeId = get(this.formData.inventoryType, "value");
      item.inventoryTypeName = get(this.formData.inventoryType, "label");
      item.quantity = get(this.formData, "quantity");
      item.remarks = get(this.formData, "remarks");
      this.orderItems.forEach((addedItem) => {
        if (addedItem.inventoryTypeId == item.inventoryTypeId) {
          isAlreadyAdded = true;
        }
      });
      if (isAlreadyAdded) {
        this.modalError = "This Inventory Type is already added";
      } else {
        this.orderItems.push(item);
        this.toggleAddItemModal(false);
      }
    },
    removeItem(itemId) {
      remove(this.orderItems, { inventoryTypeId: itemId });
    },
    confirmOrderClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.expectedDeliveryDate.$reset();
      this.v$.expectedDeliveryDate.$touch();
      if (!this.v$.expectedDeliveryDate.$invalid) {
        this.confirmOrderSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async confirmOrderSubmit() {
      var itemsList = this.orderItems;
      this.isSubmitting = true;
      const result = await createInventoryOrder({
        lineId: this.lineId,
        stationId: this.stationId,
        orderItems: itemsList,
        expectedDeliveryDate: this.expectedDeliveryDate,
        remarks: this.orderRemarks,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    editItem(item, index) {
      this.editItemIndex = index;
      this.formData.inventoryType = {
        label: get(item, "inventoryTypeName"),
        value: get(item, "inventoryTypeId"),
      };
      this.formData.quantity = get(item, "quantity");
      this.formData.remarks = get(item, "remarks");
      this.showEditItemModal = true;
      this.modalError = "";
    },
    editItemClicked() {
      if (this.isSubmitting) return;
      this.modalError = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (!this.v$.formData.$invalid) {
        let item = {};
        item.inventoryTypeId = get(this.formData.inventoryType, "value");
        item.inventoryTypeName = get(this.formData.inventoryType, "label");
        item.quantity = get(this.formData, "quantity");
        item.remarks = get(this.formData, "remarks");
        this.orderItems[this.editItemIndex] = item;
        this.closeEditItemModal();
      } else {
        this.modalError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    closeEditItemModal() {
      this.formData = {};
      this.v$.$reset();
      this.modalError = "";
      this.showEditItemModal = false;
    },
  },
};
</script>
